import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ScrollAnim from "rc-scroll-anim";
import QueueAnim from "rc-queue-anim";
import ZkCoprocessorPage from "./zkCoprocessorPage/ZkCoprocessorPage";
import PicoPage from "./pico/PicoPage";
import useIsMobile from "../hooks/useIsMobile";
import {
  setPageStatus,
  setPageToTopHeight,
  setIsFixedPosition,
  setMenuButtonDomTop,
  setIsHidden,
  setBtnAction,
} from "../redux/dataSlice";

export function preventScroll(event) {
  event.preventDefault();
}
const ContentPage = () => {
  const dispatch = useDispatch();
  const { pageStatus, pageToTopHeight, isFixedPosition, isHidden } =
    useSelector((state) => state.data);
  const [isFixedBtn, setIsFixedBtn] = useState(false);
  const [mouseInPico, setMouseInPico] = useState(false);
  const [mouseInZk, setMouseInZK] = useState(false);
  const { isMobile } = useIsMobile();
  const val = isMobile ? 60 : 200;
  const ScrollOverPack = isMobile ? "div" : ScrollAnim.OverPack;
  const MyQueueAnim = isMobile ? "div" : QueueAnim;
  const setHeightToTop = () => {
    setTimeout(() => {
      let root = document.body;
      let height = 0;
      let ele = document.getElementById("menuPosition");
      do {
        height += ele.offsetTop;
        ele = ele.offsetParent;
      } while (ele !== root);
      const toTop = isMobile ? height + 160 : height + 10;

      dispatch(setPageToTopHeight(toTop));
    }, 1000);
  };

  function getDomToViewPosition() {
    if (isMobile) {
      const startDom = document.getElementById("menuPosition");
      const endDom = document.getElementById("buttonLine");
      const rectObject = startDom.getBoundingClientRect();
      const rectObjectEnd = endDom.getBoundingClientRect();

      if (rectObject.top <= -120) {
        setIsFixedBtn(true);
        if (rectObjectEnd.top < 150) {
          setIsFixedBtn(false);
        }
      } else {
        setIsFixedBtn(false);
      }

      return;
    }
    const startDom = document.getElementById("menuButton");
    const endDom = document.getElementById("buttonLine");
    const rectObject = startDom.getBoundingClientRect();
    const rectObjectEnd = endDom.getBoundingClientRect();
    dispatch(setMenuButtonDomTop(rectObject.top, rectObject.top <= val));
    if (rectObject.top <= val) {
      dispatch(setIsFixedPosition(true));
      if (rectObjectEnd.top < 250) {
        dispatch(setIsHidden(true));
        dispatch(setBtnAction(false));
      } else {
        dispatch(setIsHidden(false));
      }
    } else {
      dispatch(setIsFixedPosition(false));
    }
  }
  function addHandler(element, type, handler) {
    if (element.addEventListener) {
      element.addEventListener(type, handler, false);
    } else if (element.attachEvent) {
      element.attachEvent("on" + type, handler);
    } else {
      element["on" + type] = handler;
    }
  }

  useEffect(() => {
    addHandler(window, "scroll", getDomToViewPosition);
    setHeightToTop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, []);

  const getClassName = () => {
    let names = "menuBtnState";
    // if (btnAction) {
    //   names = "menuBtnState btnReadyAction";
    // }
    if (isHidden && !isMobile) {
      names = names + " menuBtnStateAction";
    }
    return names;
  };

  const getMenuClassName = () => {
    let names = "menuOut";
    if (isFixedPosition) {
      names = "menuOut menuOutFixed";
    }
    // if (btnAction) {
    //   names = names + " btnReadyAction";
    // }
    return names;
  };
  const getMenuOutItemClass = () => {
    let nan = "menuOutItem";
    if (pageStatus === "pico" || mouseInPico) {
      nan = "menuOutItem menuOutActive animate__animated animate__slideInRight";
    }
    if (mouseInZk) {
      nan = "menuOutItem ";
    }
    return nan;
  };
  const getMenuOutItemRightClass = () => {
    let nan = "menuOutItem menuOutItem2";
    if (pageStatus === "zk" || mouseInZk) {
      nan =
        "menuOutItem menuOutItem2 menuOutActive animate__animated animate__slideInLeft";
    }
    if (mouseInPico) {
      nan = "menuOutItem menuOutItem2 ";
    }
    return nan;
  };

  const getTextColor = () => {
    let nan = "btnLeftText";
    if (pageStatus === "pico" || mouseInPico) {
      nan = "btnLeftText textActive";
    }
    if (mouseInZk) {
      nan = "btnLeftText ";
    }
    return nan;
  };
  const getRightTextColor = () => {
    let nan = "btnRightText";
    if (pageStatus === "zk" || mouseInZk) {
      nan = "btnRightText textActive";
    }
    if (mouseInPico) {
      nan = "btnRightText ";
    }
    return nan;
  };
  return (
    <div key="sad">
      <div
        className="menuPosition"
        id="menuPosition"
        style={{
          width: "100%",
          height: 1,
          marginBottom: 130,
        }}
      ></div>

      {!isMobile ? (
        <div className={getClassName()} id="menuButton">
          <div className={getMenuClassName()}>
            <ScrollOverPack key={"step88"} id={"step88"} playScale={0.2}>
              <MyQueueAnim type="bottom" duration={800} delay={300}>
                <div className={"menuBtn"} id="mouseTag" key="menuButton">
                  <div className={getMenuOutItemClass()}></div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(setPageStatus("pico"));
                      window.scrollTo({
                        top: pageToTopHeight,
                        behavior: "smooth",
                      });
                    }}
                    className={getTextColor()}
                    onMouseEnter={(e) => {
                      e.stopPropagation();
                      setMouseInPico(true);
                    }}
                    onMouseLeave={(e) => {
                      e.stopPropagation();
                      setMouseInPico(false);
                    }}
                  >
                    Pico zkVM
                  </div>
                  <div className={getMenuOutItemRightClass()}></div>
                  <div
                    className={getRightTextColor()}
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(setPageStatus("zk"));
                      window.scrollTo({
                        top: pageToTopHeight,
                        behavior: "smooth",
                      });
                    }}
                    onMouseEnter={(e) => {
                      e.stopPropagation();
                      setMouseInZK(true);
                    }}
                    onMouseLeave={(e) => {
                      e.stopPropagation();
                      setMouseInZK(false);
                    }}
                  >
                    zkCoprocessor
                  </div>
                </div>
              </MyQueueAnim>
            </ScrollOverPack>
          </div>
        </div>
      ) : (
        <div style={{ height: 92 }}>
          <div
            id="mobileBtn"
            className={isFixedBtn ? "mobileBtn mobileBtnFixed" : "mobileBtn"}
          >
            <div className="mobileBtnBody">
              <div
                className={
                  pageStatus === "pico"
                    ? "mobileBtnItem mobileBtnItemActive"
                    : "mobileBtnItem"
                }
                onClick={() => {
                  dispatch(setPageStatus("pico"));
                  window.scrollTo({
                    top: pageToTopHeight,
                    behavior: "smooth",
                  });
                }}
              >
                Pico zkVM
              </div>
              <div
                className={
                  pageStatus === "zk"
                    ? "mobileBtnItem mobileBtnItemActive"
                    : "mobileBtnItem"
                }
                onClick={() => {
                  dispatch(setPageStatus("zk"));
                  window.scrollTo({
                    top: pageToTopHeight,
                    behavior: "smooth",
                  });
                }}
              >
                zkCoprocessor
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="pageView" id="pageView" style={{ marginTop: 60 }}>
        <div>
          {pageStatus === "pico" ? <PicoPage /> : <ZkCoprocessorPage />}
        </div>
        <div className="buttonLine" id="buttonLine"></div>
      </div>
    </div>
  );
};
export default ContentPage;
