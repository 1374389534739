import { useEffect } from "react";
import ScrollAnim from "rc-scroll-anim";
import QueueAnim from "rc-queue-anim";
import useIsMobile from "../hooks/useIsMobile";
import incentivization from "../images/emp-incentivization.svg";
import vipIcon from "../images/emp-vip.svg";
import useCases from "../images/emp-useCases.svg";
import linkIcon from "../images/emp-link.svg";

const defiImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/emp-defi.svg";
const bridgeImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/emp-bridge.svg";
const controlImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/emp-control.svg";

export const loadImage = (url, callback) => {
  var img = new Image();
  img.onload = function () {
    img.onload = null;
    callback(img);
  };
  img.src = url;
};
const Empower = () => {
  const { isMobile } = useIsMobile();
  const ScrollOverPack = isMobile ? "div" : ScrollAnim.OverPack;
  const MyQueueAnim = isMobile ? "div" : QueueAnim;
  useEffect(() => {
    ScrollAnim.scrollScreen.unMount();
  }, []);

  const list = [
    {
      title: "Dynamic fees and rewards",
      desc: "Dynamically adjust key parameters like trading fee percentages or liquidity incentives for your protocol to improve capital efficiency, liquidity depth, and protocol revenue. ",
      imgSrc: defiImg,
      linkUrl:
        "https://blog.brevis.network/2024/06/07/next-gen-data-driven-features-for-dexes-algebra-and-brevis-launch-dynamic-fees-plugin/",
      linkText: "Algebra",
      textColor: "rgba(55, 54, 220, 1)",
    },
    {
      title: "Continuous protocol incentivization",
      desc: "Continuously incentivize positions of newly launched tokens in target markets on external DeFi protocols.",
      imgSrc: incentivization,
      linkUrl:
        "https://blog.brevis.network/2024/11/08/say-bye-to-airdrops-say-hi-to-continuous-rewards-how-brevis-and-usual-are-changing-protocol-incentivization-design/",
      linkText: "Usual",
      textColor: "rgba(55, 54, 220, 1)",
    },
  ];
  const list2 = [
    {
      title: "Intelligent risk control",
      desc: "Lending protocols can incorporate risk modules that dynamically adjust collateral ratios and interest rates based on real-time and historical liquidity and market data.",
      imgSrc: controlImg,
      linkUrl:
        "https://blog.brevis.network/2024/12/20/brevis-partners-with-mendi-to-pioneer-intelligent-lending-solutions/",
      linkText: "Mendi Finance",
      textColor: "rgba(55, 54, 220, 1)",
    },
    {
      title: "VIP loyalty programs",
      desc: "Optimize the retention and engagement of different user segments with unique loyalty features based on historical user behavior.",
      imgSrc: vipIcon,
      linkUrl:
        "https://blog.brevis.network/2024/09/09/kwenta-launches-zk-powered-trader-loyalty-program-enabled-by-brevis/",
      linkText: "Kwenta",
      textColor: "rgba(55, 54, 220, 1)",
    },
  ];
  const list3 = [
    {
      title: "On-chain reputation systems",
      desc: "Attest on-chain identities and reputation with ZK-verifiable connection and usage insights, to confirm campaign eligibility, run incentive programs, and limit Sybil activities.",
      imgSrc: useCases,
      linkUrl:
        "https://blog.brevis.network/2024/03/13/revolutionizing-the-web-3-0-ux-with-zkml-powered-by-trusta-and-brevis/",
      linkText: "Trusta Labs",
      textColor: "rgba(55, 54, 220, 1)",
    },
    {
      title: "Trustless bridges",
      desc: "Trustlessly query a message from one chain and verify the message on another without relying on external validation models.",
      imgSrc: bridgeImg,
      linkUrl: "",
      linkText: "More coming soon...",
      textColor: "rgba(23, 24, 27, 0.5)",
    },
  ];
  useEffect(() => {
    list?.forEach((item) => {
      loadImage(item.imgSrc, () => {});
    });
    list2?.forEach((item) => {
      loadImage(item.imgSrc, () => {});
    });
    list3?.forEach((item) => {
      loadImage(item.imgSrc, () => {});
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="empower">
      <div className="empower_body">
        <ScrollOverPack
          key={"empower_level1"}
          id={"empower_level1"}
          playScale={0.4}
          always={false}
        >
          <MyQueueAnim type="bottom" key={"empower_title"} duration={800}>
            <div className="empower_title" key="empower_title2">
              <div>EMPOWERING</div>
              <div>
                DATA-RICH
                {!isMobile ? <span> USE CASES</span> : <div>USE CASES</div>}
              </div>
            </div>
          </MyQueueAnim>
        </ScrollOverPack>
        <div className="empower_list">
          <ScrollOverPack
            key={"empower_level21"}
            id={"empower_level21"}
            playScale={0.4}
            always={false}
          >
            <div className="empower_level">
              {list.map((item, index) => {
                return (
                  <div
                    className="empower_item"
                    key={"empower_level11" + index.toString()}
                  >
                    <MyQueueAnim
                      type="bottom"
                      key={"empower_level111" + index.toString()}
                      duration={1500}
                    >
                      <div
                        className="empower_item_img"
                        key={"empower_item_img" + index.toString()}
                      >
                        <img src={item.imgSrc} alt="" />
                      </div>
                    </MyQueueAnim>
                    <MyQueueAnim
                      type="bottom"
                      key={"empower_level112" + index.toString()}
                      duration={1200}
                      delay={300}
                    >
                      <div
                        className="empower_item_text_title"
                        key={"empower_item_text_title" + index.toString()}
                      >
                        {item.title}
                      </div>
                    </MyQueueAnim>
                    <MyQueueAnim
                      type="bottom"
                      key={"empower_level113" + index.toString()}
                      duration={1200}
                      delay={300}
                    >
                      <div
                        className="empower_item_text_desc"
                        key={"empower_item_text_desc" + index.toString()}
                      >
                        {item.desc}
                        <div
                          className="empower_item_text_desc_link"
                          style={{ color: item.textColor }}
                          onClick={() => {
                            if (item.linkUrl) {
                              window.open(item.linkUrl, "_blank");
                            }
                          }}
                        >
                          <div className="empower_linkText_title">
                            Case Study:{" "}
                          </div>
                          <div className="empower_item_text_desc_linkText">
                            {item.linkText}
                          </div>
                          <div className="imgBody">
                            <img
                              src={linkIcon}
                              alt=""
                              width={18}
                              style={{ marginLeft: 5 }}
                              className="readDocLinkIcon"
                            />
                          </div>
                        </div>
                      </div>
                    </MyQueueAnim>
                  </div>
                );
              })}
            </div>
          </ScrollOverPack>
          <ScrollOverPack
            key={"empower_level22"}
            id={"empower_level22"}
            playScale={0.42}
            always={false}
          >
            <div className="empower_level">
              {list2.map((item, index) => {
                return (
                  <div
                    className="empower_item"
                    key={"empower_level221" + index.toString()}
                  >
                    <MyQueueAnim type="bottom" key={index} duration={1500}>
                      <div
                        className="empower_item_img"
                        key={"empower_item_img2" + index.toString()}
                      >
                        <img src={item.imgSrc} alt="" />
                      </div>
                    </MyQueueAnim>
                    <MyQueueAnim
                      type="bottom"
                      key={"empower_level331" + index.toString()}
                      duration={1200}
                      delay={300}
                    >
                      <div
                        className="empower_item_text_title"
                        key={"empower_item_text_title2" + index.toString()}
                      >
                        {item.title}
                      </div>
                    </MyQueueAnim>
                    <MyQueueAnim
                      type="bottom"
                      key={"empower_level1541" + index.toString()}
                      duration={1200}
                      delay={300}
                    >
                      <div
                        className="empower_item_text_desc"
                        key={"empower_item_text_desc2" + index.toString()}
                      >
                        {item.desc}
                        <div
                          className="empower_item_text_desc_link"
                          style={{ color: item.textColor }}
                          onClick={() => {
                            if (item.linkUrl) {
                              window.open(item.linkUrl, "_blank");
                            }
                          }}
                        >
                          <div className="empower_linkText_title">
                            Case Study:{" "}
                          </div>
                          <div className="empower_item_text_desc_linkText">
                            {item.linkText}
                          </div>
                          <div className="imgBody">
                            <img
                              src={linkIcon}
                              alt=""
                              width={18}
                              style={{ marginLeft: 5 }}
                              className="readDocLinkIcon"
                            />
                          </div>
                        </div>
                      </div>
                    </MyQueueAnim>
                  </div>
                );
              })}
            </div>
          </ScrollOverPack>
          <ScrollOverPack
            key={"empower_level3"}
            id={"empower_level3"}
            playScale={0.44}
            always={false}
          >
            <div className="empower_level">
              {list3.map((item, index) => {
                return (
                  <div
                    className="empower_item"
                    key={"empower_level1561" + index.toString()}
                  >
                    <MyQueueAnim
                      type="bottom"
                      key={"empower_level1781" + index.toString()}
                      duration={1500}
                    >
                      <div
                        className="empower_item_img"
                        key={"empower_item_img3" + index.toString()}
                      >
                        <img src={item.imgSrc} alt="" />
                      </div>
                    </MyQueueAnim>
                    <MyQueueAnim
                      type="bottom"
                      key={"empower_level1771" + index.toString()}
                      duration={1200}
                      delay={300}
                    >
                      <div
                        className="empower_item_text_title"
                        key={"empower_item_text_title3" + index.toString()}
                      >
                        {item.title}
                      </div>
                    </MyQueueAnim>
                    <MyQueueAnim
                      type="bottom"
                      key={"empower_level1871" + index.toString()}
                      duration={1200}
                      delay={300}
                    >
                      <div
                        className="empower_item_text_desc"
                        key={"empower_item_text_desc3" + index.toString()}
                      >
                        {item.desc}
                        <div
                          className="empower_item_text_desc_link"
                          style={
                            item.linkText.indexOf("soon") > -1
                              ? { color: item.textColor, cursor: "unset" }
                              : { color: item.textColor }
                          }
                          onClick={() => {
                            if (item.linkUrl) {
                              window.open(item.linkUrl, "_blank");
                            }
                          }}
                        >
                          <div className="empower_linkText_title">
                            {item.linkText.indexOf("soon") === -1 &&
                              "Case Study: "}
                          </div>
                          <div className="empower_item_text_desc_linkText">
                            {item.linkText}
                          </div>
                          {item.linkText.indexOf("soon") === -1 && (
                            <div className="imgBody">
                              <img
                                src={linkIcon}
                                alt=""
                                width={18}
                                style={{ marginLeft: 5 }}
                                className="readDocLinkIcon"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </MyQueueAnim>
                  </div>
                );
              })}
            </div>
          </ScrollOverPack>
        </div>
      </div>
    </div>
  );
};
export default Empower;
