import "animate.css";
import "./App.css";
import FirstPage from "./pages/FirstPage";

import Empower from "./pages/Empower";
import HelpYou from "./pages/HelpYou";
import Footer from "./pages/Footer";
import Teams from "./pages/Teams";
import ContentPage from "./pages/ContentPage";

export const wheelUse = () => {
  document.body.style.overflow = "auto";
  document.body.style.marginRight = "0";
  const element = document.querySelector(".header");
  element.style.setProperty("width", "100%");
};
export const wheelDisUse = () => {
  document.body.style.overflow = "hidden";
  document.body.style.marginRight = "17px";
  const element = document.querySelector(".header");
  element.style.setProperty("width", "calc(100% - 15px)");
};
function App() {
  // const wheelChange = (e) => {
  //   console.log("Scroll event triggered", new Date().getSeconds());

  //   if (isMobile) {
  //     return;
  //   }
  //   // var dom = document.getElementById("pageView");
  //   // let rectObject = dom.getBoundingClientRect();
  //   var topDom = document.getElementById("firstPage");
  //   let rectObject2 = topDom.getBoundingClientRect();
  //   // console.log(4444, rectObject2.top);
  //   // if (rectObject.top < 130) {
  //   //   dispatch(setBtnHeight(68));
  //   //   dispatch(setBtnWidth(800));
  //   //   setBtnToSmall();
  //   //   return;
  //   // }
  //   if (rectObject2.top >= 126) {
  //     dispatch(setBtnWidth(btnInitWidth));
  //     dispatch(setBtnHeight(btnInitHeight));
  //     dispatch(setIsFixedPosition(false));
  //     wheelUse();
  //     // document.removeEventListener("wheel", preventScroll);
  //     setBtnToBig();
  //     return;
  //   }

  //   if (e.deltaY > 0) {
  //     // img.style.height = `calc(100vh - 128px - ${e.deltaY})`;

  //     if (
  //       (btnHeight === 68 || btnHeight === btnInitHeight) &&
  //       menuButtonDomTop > val
  //     ) {
  //       return;
  //     }

  //     if (btnWidth - stepNum <= 800) {
  //       dispatch(setBtnWidth(800));
  //     } else {
  //       dispatch(setBtnWidth(btnWidth - stepNum));
  //     }
  //     if (btnHeight - heightStep <= 68) {
  //       dispatch(setBtnHeight(68));

  //       const element = document.querySelector(".menuBtn");
  //       element?.style.setProperty(
  //         "border",
  //         "1px solid rgba(255, 252, 228, 1)"
  //       );
  //       const element2 = document.querySelector(".menuOutItem");
  //       element2?.style.setProperty("border-radius", "10px");
  //       const element3 = document.querySelector(".menuOutItem2");
  //       element3?.style.setProperty("border-radius", "10px");
  //       wheelUse();
  //       // document.removeEventListener("wheel", preventScroll);
  //       if (!isHidden && btnHeight !== 68) {
  //         dispatch(setBtnReady(true));
  //         dispatch(setBtnAction(true));
  //       }
  //       if (btnHeight !== 68) {
  //         window.scrollTo({
  //           top: pageToTopHeight,
  //           behavior: "smooth",
  //         });
  //       }
  //     } else {
  //       wheelDisUse();
  //       // document.addEventListener("wheel", preventScroll, { passive: false });
  //       dispatch(setBtnHeight(btnHeight - heightStep));
  //       dispatch(setBtnReady(false));
  //     }
  //     if (menuButtonDomTop <= val) {
  //       dispatch(setIsFixedPosition(true));
  //     } else {
  //       dispatch(setIsFixedPosition(false));
  //     }
  //   } else {
  //     if (btnAction) {
  //       dispatch(setBtnAction(false));
  //     }
  //     if (!isInWindow) {
  //       if (btnHeight === 68) {
  //         wheelUse();
  //         document.removeEventListener("wheel", preventScroll);
  //         return;
  //       }
  //     }
  //     const hd = 50;
  //     if (btnWidth + stepNum + hd >= btnInitWidth) {
  //       dispatch(setBtnWidth(btnInitWidth));
  //     } else {
  //       dispatch(setBtnWidth(btnWidth + stepNum + hd));
  //     }
  //     if (btnHeight + heightStep + hd >= btnInitHeight) {
  //       dispatch(setBtnHeight(btnInitHeight));
  //       dispatch(setIsFixedPosition(false));
  //       dispatch(setBtnReady(false));
  //       dispatch(setBtnAction(false));
  //       wheelUse();
  //       dispatch(setIsFixedPosition(false));
  //       // document.removeEventListener("wheel", preventScroll);
  //       setBtnToBig();
  //     } else {
  //       dispatch(setBtnReady(false));
  //       dispatch(setBtnAction(false));
  //       const element = document.querySelector(".menuBtn");
  //       element.style.setProperty("border", "none");
  //       const element2 = document.querySelector(".menuOutItem");
  //       element2?.style.setProperty("border-radius", "0");
  //       const element3 = document.querySelector(".menuOutItem2");
  //       element3?.style.setProperty("border-radius", "0");
  //       wheelDisUse();
  //       // document.addEventListener("wheel", preventScroll, { passive: false });
  //       dispatch(setBtnHeight(btnHeight + heightStep + hd));
  //     }

  //     if (menuButtonDomTop <= val) {
  //       dispatch(setIsFixedPosition(true));
  //     } else {
  //       dispatch(setIsFixedPosition(false));
  //     }
  //   }
  // };

  return (
    <div className="App" id="app">
      <FirstPage />
      <ContentPage />
      <Empower />
      <Teams />
      <HelpYou />
      <Footer />
    </div>
  );
}

export default App;
