import ScrollAnim from "rc-scroll-anim";
import QueueAnim from "rc-queue-anim";
import { openTalk } from "./FirstPageContent";
import useIsMobile from "../hooks/useIsMobile";
import { openPicoDoc, openZkDoc } from "./Footer";
const helpYouImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/helpYou.svg";

const HelpYou = () => {
  const { isMobile } = useIsMobile();
  const ScrollOverPack = isMobile ? "div" : ScrollAnim.OverPack;
  const MyQueueAnim = isMobile ? "div" : QueueAnim;
  const str = "Build with Brevis".toUpperCase();
  const str4 = "Build with".toUpperCase();
  const str5 = "Build with Brevis".toUpperCase();
  return (
    <div className="helpYou">
      <div className="helpYou_body">
        <ScrollOverPack
          key={"helpYou"}
          id={"introduce"}
          playScale={0.35}
          appear={!isMobile}
          always={!isMobile}
        >
          <MyQueueAnim type="bottom" duration={1500}>
            <div className="helpYou_title" key="helpYou_title">
              {isMobile ? (
                <div>
                  <div>{str4}</div>
                  <div>{str5}</div>
                </div>
              ) : (
                <div>
                  <div>{str}</div>
                </div>
              )}
            </div>
          </MyQueueAnim>
          <MyQueueAnim type="bottom" duration={1500} delay={300}>
            <div className="helpYou_img" key={"helpYou_img"}>
              <img src={helpYouImg} alt="" />
            </div>
          </MyQueueAnim>
          <MyQueueAnim type="bottom" duration={1500} delay={600}>
            <div className="helpYou_buttons" key={"helpYou_buttons"}>
              <div
                className={
                  isMobile
                    ? "helpYou_button2"
                    : "helpYou_button2 hvr-sweep-to-right"
                }
                onClick={() => {
                  openPicoDoc();
                }}
                style={isMobile ? { color: "rgba(255, 252, 228, 1)" } : {}}
              >
                Pico docs
              </div>
              <div
                className={
                  isMobile
                    ? "helpYou_button2"
                    : "helpYou_button2 hvr-sweep-to-right"
                }
                onClick={() => {
                  openZkDoc();
                }}
                style={isMobile ? { color: "rgba(255, 252, 228, 1)" } : {}}
              >
                zkCoprocessor docs
              </div>
              <div
                className={
                  isMobile
                    ? "helpYou_button2"
                    : "helpYou_button2 hvr-sweep-to-right"
                }
                onClick={() => {
                  openTalk();
                }}
                style={isMobile ? { color: "rgba(255, 252, 228, 1)" } : {}}
              >
                Talk to a builder
              </div>
            </div>
          </MyQueueAnim>
        </ScrollOverPack>
      </div>
    </div>
  );
};
export default HelpYou;
