import cover from "../../images/cover.png";
import docTorRight from "../../images/docTorRight.svg";
import { openZkDoc } from "../Footer";

function Video() {
  return (
    <div className="videoPage">
      <div className="videoOut">
        <video
          className="videoContent"
          controls
          // autoPlay
          poster={cover}
          preload="auto"
          playsInline
          // controlsList="nodownload nofullscreen noremoteplayback noplaybackrate"
          disablePictureInPicture
        >
          <source
            src="https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/video/introduction.mp4"
            type="video/mp4"
          ></source>
        </video>
      </div>
      <div
        className="readDocLink"
        onClick={() => {
          openZkDoc();
        }}
      >
        Read docs
        <div className="imgBody">
          <img
            src={docTorRight}
            alt=""
            width={18}
            style={{ marginLeft: 5 }}
            className="readDocLinkIcon"
          />
        </div>
      </div>
    </div>
  );
}

export default Video;
