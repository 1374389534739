import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useIsMobile from "../hooks/useIsMobile";
import { openTalk } from "./FirstPageContent";
import {
  setPageStatus,
  setIsFixedPosition,
  setBtnHeight,
  setBtnWidth,
} from "../redux/dataSlice";
import { openPicoDoc, openZkDoc } from "./Footer";
const closeImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/close.svg";
const menuImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/menu.svg";
const logoImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/logo.svg";

export const openWhitePaper = () => {
  window.open(
    " https://get.celer.app/brevis/BrevisWhitePaper_09271015.pdf",
    "_blank"
  );
};
export const openBlog = () => {
  window.open("https://blog.brevis.network/", "_blank");
};
export const openDoc = () => {
  window.open("https://docs.brevis.network/", "_blank");
};
export const openTelegram = () => {
  window.open("https://t.me/brevisnetwork", "_blank");
};
export const openDiscord = () => {
  window.open("https://discord.com/invite/QTRkjKdZ6A", "_blank");
};
const Header = () => {
  const { isMobile } = useIsMobile();
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const [docIn, setDocIn] = useState(false);
  // const [whitepaperIn, setWhitepaperIn] = useState(false);
  const [blogIn, setBlogIn] = useState(false);
  const [zkIn, setZkIn] = useState(false);
  const [picIn, setPicIn] = useState(false);
  const [tegIn, setTegIn] = useState(false);
  const [discordIn, setDiscordIn] = useState(false);
  const { pageToTopHeight } = useSelector((state) => state.data);

  const setBtnPosition = () => {
    const element = document.querySelector(".menuBtn");
    element?.style.setProperty("border", "1px solid rgba(255, 252, 228, 1)");
    const element2 = document.querySelector(".menuOutItem");
    element2?.style.setProperty("border-radius", "10px");
    const element3 = document.querySelector(".menuOutItem2");
    element3?.style.setProperty("border-radius", "10px");
    dispatch(setBtnWidth(800));
    dispatch(setBtnHeight(68));
    dispatch(setIsFixedPosition(true));
  };

  return (
    <div className="header">
      {isMobile && (
        <div className="mobileHeader">
          <img
            alt=""
            className="mobileLogo"
            src={logoImg}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          />
          {!showMenu ? (
            <img
              alt=""
              className="menuIcon"
              src={menuImg}
              onClick={() => {
                document.body.style.overflow = "hidden";
                setShowMenu(true);
              }}
            />
          ) : (
            <img
              alt=""
              className="menuIcon"
              src={closeImg}
              onClick={() => {
                setShowMenu(false);
                document.body.style.overflow = "auto";
              }}
            />
          )}
        </div>
      )}
      {(showMenu || !isMobile) && (
        <div className="headerCenter" id="headerCenter">
          <div className="headerLeft">
            {!isMobile && (
              <img
                alt=""
                className="logo"
                src="https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/logo.svg"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              />
            )}
            <div
              className="headerBtn"
              onClick={() => {
                dispatch(setPageStatus("pico"));
                // document.querySelector("#picoPage").scrollIntoView(true);
                if (isMobile) {
                  setShowMenu(false);
                } else {
                  setBtnPosition();
                }

                window.scrollTo({
                  top: pageToTopHeight,
                  behavior: "smooth",
                });
              }}
              onMouseEnter={() => {
                setZkIn(true);
              }}
              onMouseLeave={() => {
                setZkIn(false);
              }}
            >
              {isMobile ? (
                "Pico zkVM"
              ) : (
                <div
                  className={zkIn ? "textMotion_cubeIn" : "textMotion_cubeOut"}
                  style={
                    navigator.userAgent.includes("Safari") &&
                    !navigator.userAgent.includes("Chrome")
                      ? { width: 114 }
                      : { width: 104 }
                  }
                >
                  <div className="textMotion_cube_item_front">Pico zkVM</div>
                  <div className="textMotion_cube_item_bottom">Pico zkVM</div>
                </div>
              )}
            </div>
            <div
              className="headerBtn"
              onClick={() => {
                dispatch(setPageStatus("zk"));
                if (isMobile) {
                  setShowMenu(false);
                } else {
                  setBtnPosition();
                }
                // document.querySelector("#zkPage").scrollIntoView(true);
                window.scrollTo({
                  top: pageToTopHeight,
                  behavior: "smooth",
                });
              }}
              onMouseEnter={() => {
                setPicIn(true);
              }}
              onMouseLeave={() => {
                setPicIn(false);
              }}
            >
              {isMobile ? (
                "zkCoprocessor"
              ) : (
                <div
                  className={picIn ? "textMotion_cubeIn" : "textMotion_cubeOut"}
                  style={{ width: 152 }}
                >
                  <div className="textMotion_cube_item_front">
                    zkCoprocessor
                  </div>
                  <div className="textMotion_cube_item_bottom">
                    zkCoprocessor
                  </div>
                </div>
              )}
            </div>
            {isMobile ? (
              <>
                <div
                  className="headerBtn"
                  onClick={() => {
                    openPicoDoc();
                  }}
                >
                  Pico zkVM Docs
                </div>
                <div
                  className="headerBtn"
                  onClick={() => {
                    openZkDoc();
                  }}
                >
                  zkCoprocessor Docs
                </div>
              </>
            ) : (
              <div
                className="headerBtn docs"
                onMouseEnter={() => {
                  setDocIn(true);
                }}
                onMouseLeave={() => {
                  setDocIn(false);
                }}
              >
                {isMobile ? (
                  "Docs"
                ) : (
                  <div
                    className={
                      docIn ? "textMotion_cubeIn" : "textMotion_cubeOut"
                    }
                    style={{ width: 50 }}
                  >
                    <div className="textMotion_cube_item_front">Docs</div>
                    <div className="textMotion_cube_item_bottom">Docs</div>
                  </div>
                )}

                <div
                  className="docDropDown animate__animated animate__slideInUp"
                  style={docIn ? { display: "block" } : { display: "none" }}
                >
                  <div
                    className="docDropDownText"
                    onClick={() => {
                      openPicoDoc();
                    }}
                  >
                    <div
                      className="headerBtn"
                      style={{ marginLeft: 0, paddingLeft: 32, paddingTop: 28 }}
                      // onMouseEnter={() => {
                      //   setPicoIn(true);
                      // }}
                      // onMouseLeave={() => {
                      //   setPicoIn(false);
                      // }}
                    >
                      {isMobile ? (
                        "Pico zkVM"
                      ) : (
                        <div
                          // className={
                          //   picoIn ? "textMotion_cubeIn" : "textMotion_cubeOut"
                          // }
                          style={{ height: 20 }}
                        >
                          <div className="textMotion_cube_item_front">
                            Pico zkVM
                          </div>
                          <div className="textMotion_cube_item_bottom">
                            Pico zkVM
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="docDropDownText"
                    onClick={() => {
                      openZkDoc();
                    }}
                  >
                    <div
                      className="headerBtn"
                      style={{ marginLeft: 0, paddingLeft: 32, paddingTop: 28 }}
                      // onMouseEnter={() => {
                      //   setZkCoprocessorIn(true);
                      // }}
                      // onMouseLeave={() => {
                      //   setZkCoprocessorIn(false);
                      // }}
                    >
                      {isMobile ? (
                        "zkCoprocessor"
                      ) : (
                        <div
                          // className={
                          //   zkCoprocessorIn
                          //     ? "textMotion_cubeIn"
                          //     : "textMotion_cubeOut"
                          // }
                          style={{ height: 20 }}
                        >
                          <div className="textMotion_cube_item_front">
                            zkCoprocessor
                          </div>
                          <div className="textMotion_cube_item_bottom">
                            zkCoprocessor
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* <div
              className="headerBtn"
              onClick={() => {
                openWhitePaper();
              }}
              onMouseEnter={() => {
                setWhitepaperIn(true);
              }}
              onMouseLeave={() => {
                setWhitepaperIn(false);
              }}
            >
              {isMobile ? (
                "Whitepaper"
              ) : (
                <div
                  className={
                    whitepaperIn ? "textMotion_cubeIn" : "textMotion_cubeOut"
                  }
                  style={{ width: 111 }}
                >
                  <div className="textMotion_cube_item_front">Whitepaper</div>
                  <div className="textMotion_cube_item_bottom">Whitepaper</div>
                </div>
              )}
            </div> */}
            <div
              className="headerBtn"
              onClick={() => {
                openBlog();
              }}
              onMouseEnter={() => {
                setBlogIn(true);
              }}
              onMouseLeave={() => {
                setBlogIn(false);
              }}
            >
              {isMobile ? (
                "Blog"
              ) : (
                <div
                  className={
                    blogIn ? "textMotion_cubeIn" : "textMotion_cubeOut"
                  }
                  style={{ width: 45 }}
                >
                  <div className="textMotion_cube_item_front">Blog</div>
                  <div className="textMotion_cube_item_bottom">Blog</div>
                </div>
              )}
            </div>
            <div
              className="headerBtn"
              onClick={() => {
                openTelegram();
              }}
              onMouseEnter={() => {
                setTegIn(true);
              }}
              onMouseLeave={() => {
                setTegIn(false);
              }}
            >
              {isMobile ? (
                "Telegram"
              ) : (
                <div
                  className={tegIn ? "textMotion_cubeIn" : "textMotion_cubeOut"}
                  style={{ width: 100 }}
                >
                  <div className="textMotion_cube_item_front">Telegram</div>
                  <div className="textMotion_cube_item_bottom">Telegram</div>
                </div>
              )}
            </div>
            <div
              className="headerBtn"
              onClick={() => {
                openDiscord();
              }}
              onMouseEnter={() => {
                setDiscordIn(true);
              }}
              onMouseLeave={() => {
                setDiscordIn(false);
              }}
            >
              {isMobile ? (
                "Discord"
              ) : (
                <div
                  className={
                    discordIn ? "textMotion_cubeIn" : "textMotion_cubeOut"
                  }
                  style={{ width: 78 }}
                >
                  <div className="textMotion_cube_item_front">Discord</div>
                  <div className="textMotion_cube_item_bottom">Discord</div>
                </div>
              )}
            </div>
          </div>
          <div className="headerRight">
            <div
              className={
                isMobile ? "headerButton" : "headerButton hvr-sweep-to-right"
              }
              onClick={() => {
                openTalk();
              }}
              style={isMobile ? { color: "rgba(255, 252, 228, 1)" } : {}}
            >
              Talk to a builder
            </div>
            {/* <div
              className="headerButton2"
              onClick={() => {
                openDemo();
              }}
            >
              Try demos
            </div> */}
          </div>
        </div>
      )}
    </div>
  );
};
export default Header;
