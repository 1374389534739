import { useSelector, useDispatch } from "react-redux";
import useIsMobile from "../hooks/useIsMobile";
import { setPageStatus } from "../redux/dataSlice";
const logoImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/logo.svg";
const picoZkVMDocs = "https://pico-docs.brevis.network/";
const zkCoprocessorDocs = "https://coprocessor-docs.brevis.network/";
export const openPicoDoc = () => {
  window?.open(picoZkVMDocs, "_blank");
};
export const openZkDoc = () => {
  window?.open(zkCoprocessorDocs, "_blank");
};
const Footer = () => {
  const year = new Date().getFullYear();
  const { isMobile } = useIsMobile();

  const dispatch = useDispatch();
  const { pageToTopHeight } = useSelector((state) => state.data);
  const resources = [
    {
      text: "BLOG",
      link: "https://blog.brevis.network/",
    },
    {
      text: "GITHUB",
      link: " https://github.com/brevis-network/",
    },
    {
      text: "Pico zkVM Docs",
      link: picoZkVMDocs,
    },

    {
      text: "zkCoprocessor Docs",
      link: zkCoprocessorDocs,
    },
  ];
  const contacts = [
    {
      text: "TALK TO A BUILDER",
      link: "https://form.typeform.com/to/lduiliob",
    },
    {
      text: "X (Twitter)",
      link: "https://twitter.com/brevis_zk",
    },
    {
      text: "JOIN TELEGRAM",
      link: "https://t.me/brevisnetwork",
    },
    {
      text: "JOIN DISCORD",
      link: "https://discord.com/invite/QTRkjKdZ6A",
    },
  ];
  return (
    <div className="footer">
      <div className="footer_body">
        <div className="footer_level">
          <div className="footer_left">
            <img
              src={logoImg}
              className="footer_img"
              alt=""
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            />
            {!isMobile && (
              <div className="footer_reserved">
                © Brevis {year}. All rights reserved.
              </div>
            )}
          </div>
          <div className="footer_right">
            <div className="footer_resources">
              <div
                className="footer_resourcesText"
                style={{ color: "rgba(243, 194, 8, 1)" }}
              >
                PRODUCTS
              </div>
              <div
                className="footer_link_item"
                onClick={() => {
                  dispatch(setPageStatus("pico"));
                  // document.querySelector("#picoPage").scrollIntoView(true);
                  window.scrollTo({
                    top: pageToTopHeight,
                    behavior: "smooth",
                  });
                }}
              >
                PICO ZKVM
              </div>
              <div
                className="footer_link_item"
                onClick={() => {
                  dispatch(setPageStatus("zk"));
                  // document.querySelector("#zkPage").scrollIntoView(true);
                  window.scrollTo({
                    top: pageToTopHeight,
                    behavior: "smooth",
                  });
                }}
              >
                ZKCOPROCESSOR
              </div>
            </div>
            <div className="footer_resources">
              <div className="footer_resourcesText">RESOURCES</div>
              {resources.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="footer_link_item"
                    onClick={() => {
                      window?.open(item.link, "_blank");
                    }}
                  >
                    {item.text.toUpperCase()}
                  </div>
                );
              })}
            </div>
            <div className="footer_contactUs">
              <div className="footer_resourcesText2">CONTACT US</div>
              {contacts.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="footer_link_item"
                    onClick={() => {
                      window?.open(item.link, "_blank");
                    }}
                  >
                    {item.text.toUpperCase()}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {isMobile && (
          <div className="footer_reserved">
            © Brevis {year}. All rights reserved.
          </div>
        )}
      </div>
    </div>
  );
};
export default Footer;
