import { useEffect } from "react";
import ScrollAnim from "rc-scroll-anim";
import QueueAnim from "rc-queue-anim";
import { loadImage } from "../Empower";
import useIsMobile from "../../hooks/useIsMobile";
const readImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/read.svg";
const computeImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/compute.svg";
const verifyImg =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/verify.svg?tag=1";

const Step = () => {
  const { isMobile } = useIsMobile();
  const ScrollOverPack = isMobile ? "div" : ScrollAnim.OverPack;
  const MyQueueAnim = isMobile ? "div" : QueueAnim;
  const list = [
    {
      title: "STEP 1",
      subTitle: "ACCESS DATA",
      desc: (
        <div>
          With zkCoprocessor’s versatile Go SDK, smart contracts can access
          blockchain states, transactions, and receipts across multiple
          blockchains and timeframes.
        </div>
      ),
      imgSrc: readImg,
      colorType: "rgba(17, 144, 89, 1)",
    },
    {
      title: "STEP 2",
      subTitle: "COMPUTE ON DATA",
      desc: "DApps can transform complex business logic that are expensive to execute with smart contracts into succinct, low-cost circuit outputs efficiently computed with ZK-verified on-chain data.",
      imgSrc: computeImg,
      colorType: "rgba(249, 110, 71, 1)",
    },
    {
      title: "STEP 3",
      subTitle: "USE RESULT",
      desc: (
        <div>
          The computation result, along with a ZK proof attesting to the source
          data and computation execution, is submitted for smart contracts on
          any supported blockchain to seamlessly verify and use it.
        </div>
      ),
      imgSrc: verifyImg,
      colorType: "rgba(249, 166, 225, 1)",
    },
  ];
  useEffect(() => {
    list?.forEach((item) => {
      loadImage(item.imgSrc, () => {});
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="step">
      <div className="step_body">
        <div className="step_list">
          {list.map((item, index) => {
            return (
              <ScrollOverPack
                key={"step" + index.toString()}
                id={"step" + index.toString()}
                playScale={0.3 + (index + 1) * 0.02}
              >
                <div
                  className={index % 2 !== 0 ? "step_item2" : "step_item"}
                  key={index}
                >
                  <div
                    className={
                      index % 2 !== 0
                        ? "step_item_text_special"
                        : "step_item_text"
                    }
                  >
                    <MyQueueAnim type="bottom" duration={1500} delay={500}>
                      <div
                        className="step_item_text_title"
                        style={{ color: item.colorType }}
                        key={item.title}
                      >
                        {item.title}
                      </div>
                    </MyQueueAnim>
                    <MyQueueAnim type="bottom" duration={1500} delay={500}>
                      <div
                        className="step_item_text_subTitle"
                        key={item.subTitle}
                      >
                        {item.subTitle}
                      </div>
                    </MyQueueAnim>
                    <MyQueueAnim type="bottom" duration={1500} delay={500}>
                      <div
                        className="step_item_text_desc"
                        key={item.title + "1"}
                      >
                        {item.desc}
                      </div>
                    </MyQueueAnim>
                  </div>
                  <div className="step_item_img">
                    <MyQueueAnim type="bottom" duration={1500} delay={500}>
                      <img src={item.imgSrc} alt="" key={item.title + "2"} />
                    </MyQueueAnim>
                  </div>
                </div>
              </ScrollOverPack>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Step;
