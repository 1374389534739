import { useEffect } from "react";
import ScrollAnim from "rc-scroll-anim";
import QueueAnim from "rc-queue-anim";
import { useSelector } from "react-redux";
import { loadImage } from "../Empower";
import useIsMobile from "../../hooks/useIsMobile";
import docTorRight from "../../images/docTorRight.svg";
const pico1 =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/pico1.svg";
const pStep1 =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/pStep1.svg";
const pStep2 =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/pStep2.svg";
const pStep3 =
  "https://getcelerapp.s3.us-west-1.amazonaws.com/brevis-website/images/pStep3.svg";

const Step = () => {
  const { isMobile } = useIsMobile();
  const ScrollOverPack = isMobile ? "div" : ScrollAnim.OverPack;
  const MyQueueAnim = isMobile ? "div" : QueueAnim;
  const { pageToTopHeight } = useSelector((state) => state.data);
  const list = [
    {
      title: "STEP 1",
      subTitle: "FLEXIBLE PROVING FIELDS AND SYSTEMS",
      desc: "Pico provides developers with a broad selection of different prime fields, including BabyBear, KoalaBear, and Mersenne31, along with various proving systems. This flexibility allows you to selectively enable specialized circuits while falling back on general-purpose circuits for more complex application logic. Experiment with different configurations to get immediate performance improvements, and enjoy seamless upgrades as new fields and proving systems are integrated into the zkVM.",
      imgSrc: pStep1,
      colorType: "rgba(164, 137, 243, 1)",
    },
    {
      title: "STEP 2",
      subTitle: (
        <div>
          CUSTOMIZABLE<div>PROVING WORKFLOWS</div>
        </div>
      ),
      desc: "Pico’s composable VM allows developers to customize an instance of a single machine proving process to configure the fields, proving systems, and chips based on app-specific needs. Additionally, Pico allows developers to customize proving workflows with ease—enabling you to add, adjust, or remove instances within a workflow to achieve optimal performance and flexibility.",
      imgSrc: pStep2,
      colorType: "rgba(17, 144, 89, 1)",
    },
    {
      title: "STEP 3",
      subTitle: "APP-SPECIFIC CIRCUITS",
      desc: (
        <div>
          Pico’s modular architecture allows seamless integration of
          app-specific circuits, either as coprocessors or precompiles. You can
          develop and integrate custom circuits as coprocessors tailored to your
          application’s needs, and common, specialized cryptographic operations
          as precompiles to significantly reduce proof generation time and
          complexity for standard tasks.
        </div>
      ),
      imgSrc: pStep3,
      colorType: "rgba(243, 194, 8, 1)",
    },
  ];
  useEffect(() => {
    list?.forEach((item) => {
      loadImage(item.imgSrc, () => {});
    });
    window.scrollTo({
      top: pageToTopHeight + 1,
      behavior: "smooth",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="step">
      <div className="step_body" style={{ minHeight: 2948 }}>
        <div className="step_list" style={{ marginTop: 60 }}>
          <ScrollOverPack key={"step8"} id={"step8"} playScale={0.22}>
            <div className={"pico_item"} key={0}>
              <div className="pico_item_left">
                <MyQueueAnim type="bottom" duration={800} delay={500}>
                  <div className="picoGlue " key={"433"}>
                    WITH A <span style={{ color: "#343dd3" }}>GLUE</span>-AND-
                    <span className="changeTextColor">COPROCESSOR</span>{" "}
                    ARCHITECTURE
                  </div>
                </MyQueueAnim>
                {isMobile && (
                  <img src={pico1} width={"100%"} alt="" key={"235"} />
                )}
                <MyQueueAnim type="bottom" duration={1000} delay={700}>
                  <div className="picoDec" key={"135"}>
                    <div style={{ textAlign: "left" }}>
                      Pico combines broad applicability via a general-purpose
                      zkVM ("glue") with high performance through specialized
                      circuits ("coprocessors"). Going beyond precompiles that
                      accelerate low-level operations like hash and signature
                      verification, Pico supports app-level coprocessors that
                      organize specialized circuits using app-aware systems
                      effectively.
                      <div style={{ marginTop: 10 }}>
                        With a Rust-based interface, Pico simplifies prototyping
                        and accelerates development within a universal
                        framework. Its coprocessors are purpose-built for
                        specialized tasks such as arithmetic, cryptography,
                        historical on-chain data access and computation, and
                        machine learning, delivering significantly enhanced
                        performance.
                      </div>
                      <div
                        className="readDocLink"
                        style={{ marginTop: 52 }}
                        onClick={() => {
                          window.open(
                            "https://blog.brevis.network/2025/02/11/introducing-pico-a-modular-and-performant-zkvm/",
                            "_blank"
                          );
                        }}
                      >
                        <div className="readDocLinkText">Learn more</div>
                        <div className="imgBody">
                          <img
                            src={docTorRight}
                            alt=""
                            width={18}
                            style={{ marginLeft: 5 }}
                            className="readDocLinkIcon"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </MyQueueAnim>
              </div>
              {!isMobile && (
                <MyQueueAnim type="bottom" duration={800} delay={800}>
                  <div className="picoImg" key={"235"}>
                    <img src={pico1} alt="" />
                  </div>
                </MyQueueAnim>
              )}
            </div>
          </ScrollOverPack>
          <div className="stepLine">
            <div className="stepLineInner"> </div>
          </div>
          <div>
            {list.map((item, index) => {
              return (
                <ScrollOverPack
                  key={"step" + index.toString()}
                  id={"step" + index.toString()}
                  playScale={0.23}
                >
                  <div
                    className={
                      index % 2 !== 0 ? "step_item_pico" : "step_item2_pico"
                    }
                    key={index}
                  >
                    <div
                      className={
                        index % 2 !== 0
                          ? "step_item_text_pico"
                          : "step_item_text_special_pico"
                      }
                    >
                      <MyQueueAnim type="bottom" duration={1000}>
                        <div
                          className={
                            index % 2 === 0
                              ? "step_item_text_title_pico"
                              : "step_item_text_title_pico_sp"
                          }
                          key={item.title}
                        >
                          <div
                            className="spDot"
                            style={{ background: item.colorType }}
                          ></div>
                        </div>
                      </MyQueueAnim>
                      <MyQueueAnim type="bottom" duration={1000}>
                        <div
                          className="step_item_text_subTitle_pico"
                          key={item.subTitle}
                        >
                          {item.subTitle}
                        </div>
                      </MyQueueAnim>
                      <MyQueueAnim type="bottom" duration={1000}>
                        <div
                          className="step_item_text_desc"
                          key={item.title + "1"}
                        >
                          {item.desc}
                        </div>
                      </MyQueueAnim>
                    </div>
                    <div className="step_item_img_pico">
                      <MyQueueAnim type="bottom" duration={1000}>
                        <img
                          src={item.imgSrc}
                          className="step_item_img_pico_img"
                          alt=""
                          key={item.title + "2"}
                        />
                      </MyQueueAnim>
                    </div>
                  </div>
                </ScrollOverPack>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Step;
