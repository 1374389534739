import Step from "./Step";
import Introduce from "./Introduce";
import Video from "./Video";
import QueueAnim from "rc-queue-anim";
import useIsMobile from "../../hooks/useIsMobile";

const ZkCoprocessorPage = () => {
  const { isMobile } = useIsMobile();
  const MyQueueAnim = isMobile ? "div" : QueueAnim;
  return (
    <div id="zkPage">
      <MyQueueAnim type="bottom" duration={800} delay={300}>
        <div className="zkTitle" key="coprocessor">
          AN OPTIMIZED COPROCESSOR <div>FOCUSED ON DATA-PROCESSING</div>
        </div>
      </MyQueueAnim>
      <MyQueueAnim type="bottom" duration={1500} delay={300}>
        <div key="video">
          <Video />
        </div>
      </MyQueueAnim>

      <Step />
      <Introduce />
    </div>
  );
};
export default ZkCoprocessorPage;
