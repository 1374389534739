import gg from "../images/teamsIcon/0G.svg";
import gg2 from "../images/teamsIcon/0G2.svg";
import AlgebraLabs from "../images/teamsIcon/AlgebraLabs.svg";
import AlgebraLabs2 from "../images/teamsIcon/AlgebraLabs2.svg";
import Bedrock from "../images/teamsIcon/Bedrock.svg";
import Bedrock2 from "../images/teamsIcon/Bedrock2.svg";
import celer from "../images/teamsIcon/celer.svg";
import celer2 from "../images/teamsIcon/celer2.svg";
import Gamma from "../images/teamsIcon/Gamma.svg";
import Gamma2 from "../images/teamsIcon/Gamma2.svg";
import Hemera from "../images/teamsIcon/Hemera.svg";
import Hemera2 from "../images/teamsIcon/Hemera2.svg";
import JoJoExchange from "../images/teamsIcon/JoJoExchange.svg";
import JoJoExchange2 from "../images/teamsIcon/JoJoExchange2.svg";
import Kernel from "../images/teamsIcon/Kernel.svg";
import Kernel2 from "../images/teamsIcon/Kernel2.svg";
import KimProtocol from "../images/teamsIcon/KimProtocol.svg";
import KimProtocol2 from "../images/teamsIcon/KimProtocol2.svg";
import Kwenta from "../images/teamsIcon/Kwenta.svg";
import Kwenta2 from "../images/teamsIcon/Kwenta2.svg";
import MaskNetwork from "../images/teamsIcon/MaskNetwork.svg";
import MaskNetwork2 from "../images/teamsIcon/MaskNetwork2.svg";
import MellowFinance from "../images/teamsIcon/MellowFinance.svg";
import MellowFinance2 from "../images/teamsIcon/MellowFinance2.svg";
import MendiFinance from "../images/teamsIcon/MendiFinance.svg";
import MendiFinance2 from "../images/teamsIcon/MendiFinance2.svg";
import pancakeswap from "../images/teamsIcon/pancakeswap.svg";
import pancakeswap2 from "../images/teamsIcon/pancakeswap2.svg";
import Quickswap from "../images/teamsIcon/Quickswap.svg";
import Quickswap2 from "../images/teamsIcon/Quickswap2.svg";
import Thena from "../images/teamsIcon/Thena.svg";
import Thena2 from "../images/teamsIcon/Thena2.svg";
import Trusta from "../images/teamsIcon/Trusta.svg";
import Trusta2 from "../images/teamsIcon/Trusta2.svg";
import Usual from "../images/teamsIcon/Usual.svg";
import Usual2 from "../images/teamsIcon/Usual2.svg";
import ZettaBlock from "../images/teamsIcon/ZettaBlock.svg";
import ZettaBlock2 from "../images/teamsIcon/ZettaBlock2.svg";
import bera from "../images/teamsIcon/bera.svg";
import bera2 from "../images/teamsIcon/bera2.svg";
import useIsMobile from "../hooks/useIsMobile";
const Teams = () => {
  const { isMobile } = useIsMobile();
  const list1 = [
    { colorImg: pancakeswap, whiteImg: pancakeswap2 },
    { colorImg: JoJoExchange, whiteImg: JoJoExchange2 },
    { colorImg: Hemera, whiteImg: Hemera2 },
    { colorImg: Usual, whiteImg: Usual2 },
  ];
  const list2 = [
    { colorImg: celer, whiteImg: celer2 },
    { colorImg: Gamma, whiteImg: Gamma2 },
    { colorImg: KimProtocol, whiteImg: KimProtocol2 },
    { colorImg: MendiFinance, whiteImg: MendiFinance2 },
  ];
  const list3 = [
    { colorImg: Quickswap, whiteImg: Quickswap2 },
    { colorImg: Trusta, whiteImg: Trusta2 },
    { colorImg: gg, whiteImg: gg2 },
    { colorImg: bera, whiteImg: bera2 },
  ];
  const list4 = [
    { colorImg: Kwenta, whiteImg: Kwenta2 },
    { colorImg: MaskNetwork, whiteImg: MaskNetwork2 },
    { colorImg: Bedrock, whiteImg: Bedrock2 },
    { colorImg: Thena, whiteImg: Thena2 },
  ];
  const list5 = [
    { colorImg: Kernel, whiteImg: Kernel2 },
    { colorImg: MellowFinance, whiteImg: MellowFinance2 },
    { colorImg: Usual, whiteImg: Usual2 },
    { colorImg: Quickswap, whiteImg: Quickswap2 },
  ];
  const list6 = [
    { colorImg: AlgebraLabs, whiteImg: AlgebraLabs2 },
    { colorImg: Thena, whiteImg: Thena2 },
    { colorImg: ZettaBlock, whiteImg: ZettaBlock2 },
    { colorImg: pancakeswap, whiteImg: pancakeswap2 },
  ];
  const dataList = [list1, list2, list3, list4, list5, list6];
  const mobileList = list1
    .slice(0, 3)
    .concat(list2)
    .concat(list3)
    .concat(list4.slice(0, 3))
    .concat(list5.slice(0, 3))
    .concat(list6.slice(0, 3));
  return (
    <div className="teams">
      <div className="teamsTitle">
        TRUSTED BY <span style={{ color: "#ecc243" }}>THE BEST</span> TEAMS
      </div>
      <div className="teamsList">
        {!isMobile ? (
          dataList?.map((item, index) => {
            return (
              <div className="teamsListItem " key={index}>
                <div
                  className="imgMotion_cubeIn"
                  style={{ animationDelay: 0.1 * index + "s" }}
                >
                  {item?.map((iitem, iindex) => {
                    return (
                      <div
                        className={`teamsListItemText imgMotion_item_${
                          iindex + 1
                        }`}
                        key={iindex}
                      >
                        <img
                          className="teamsListItemTextColor"
                          src={iitem.colorImg}
                          alt=""
                        />
                        <img
                          className={`teamsListItemTextWhite`}
                          src={iitem.whiteImg}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
        ) : (
          <div className="mobileList">
            {mobileList?.map((item, index) => {
              return (
                <div
                  className={
                    (index + 1) % 3 !== 0
                      ? "mobileListItem"
                      : "mobileListItem bottomLine"
                  }
                >
                  <img src={item.whiteImg} className="teamsImg" alt="" />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
export default Teams;
